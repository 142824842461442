#chatbox {
  height: 70vh;
  width: 70vw;
  background:rgba(144, 148, 150, 0.318);
  overflow-y: scroll;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

.text {
  padding: 20px 0px 0px 1vw;
}

.transcript {
  padding: 0px 0px 0px 5vw;
}

.correction {
  padding: 0px 0px 0px 10vw;
}

.p-text {
  display: inline-block;
  padding: 3px 3px 3px 3px;
  background-color:rgba(103, 174, 56, 0.675);
  border-radius: 10px;
}

.p-transcript {
  display: inline-block;
  padding: 3px 3px 3px 3px;
  background-color: rgba(86, 153, 220, 0.38);
  border-radius: 10px;
}

.p-correction {
  display: inline-block;
  padding: 3px 3px 3px 3px;
  background-color:white;
  border-radius: 10px;
}

.correction-textarea {
  width: 50vw;
}

